<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item label="班组">
              <a-select
                class="form-control lg test"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :filterOption="filterByPY"
                :options="leaderList"
              ></a-select>
            </a-form-item>
            <a-form-item label="工人">
              <a-input class="form-control" v-decorator="['workerName']" placeholder="请输入工人姓名"></a-input>
            </a-form-item>
            <a-form-item label="激活状态">
              <a-select
                class="form-control"
                v-decorator="['status']"
                placeholder="请选择激活状态"
                allowClear
                :options="typesMap.status"
              ></a-select>
            </a-form-item>
            <a-form-item label="申请时间">
              <a-select
                class="form-control mutiple-select"
                :options="monthOptions"
                v-model="months"
                mode="multiple"
                placeholder="选择考勤月份"
              ></a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="check-circle" @click="batchChangeState(selectedRows, 1)">批量激活</a-button>
          <a-button icon="close-circle" @click="batchChangeState(selectedRows, 0)">批量失效</a-button>
          <a-upload name="file" @change="handleUploadChange" :beforeUpload="beforeUpload" :customRequest="doUpload">
            <template>
              <a-button>
                <a-icon type="upload" />
                批量导入
              </a-button>
              <a @click.stop="download">
                <a-icon type="download"></a-icon>
                模板下载
              </a>
            </template>
          </a-upload>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
            :rowSelection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onRowSelectChange,
              type: 'checkbox',
              columnWidth: '2%',
            }"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form">
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">申请人：</div>
            <div class="info-col-content">{{ modal.data.workerName }}</div>
          </div>
          <div class="info-col">
            <div class="info-col-label">电话：</div>
            <div class="info-col-content">{{ modal.data.workerPhoneNumber }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">身份证号：</div>
            <div class="info-col-content">{{ modal.data.workerCardNumber }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">班组：</div>
            <div class="info-col-content">{{ modal.data.leaderName }}</div>
          </div>
          <div class="info-col">
            <div class="info-col-label">班组电话：</div>
            <div class="info-col-content">{{ modal.data.leaderPhoneNumber }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">项目：</div>
            <div class="info-col-content">{{ modal.data.projectName }}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">考勤记录：</div>
            <div class="info-col-content">
              <table class="attendance-table" v-if="modal.data.inOutVOList">
                <tr class="bg-gray-300">
                  <td>进场时间</td>
                  <td>出场时间</td>
                </tr>
                <tr v-for="(item, index) in modal.data.inOutVOList" :key="index">
                  <td>{{ item.inn }}</td>
                  <td>{{ item.out }}</td>
                </tr>
              </table>

              <span v-else>无</span>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="flex-1 info-col">
            <div class="info-col-label">异常考勤解释：</div>
            <div class="flex-1 info-col-content">
              <a-form-item>
                <a-textarea
                  v-decorator="[
                    'abnormalReason',
                    {
                      rules: [{ required: true, message: '请输入' }],
                    },
                  ]"
                  placeholder="请输入异常考勤解释"
                ></a-textarea>
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="leading-10 info-row">
          <div class="info-col">
            <div class="info-col-label">进场：</div>
            <div class="info-col-content">
              <a-form-item>
                <a-time-picker
                  format="HH:mm:ss"
                  v-decorator="[
                    'inn',
                    {
                      rules: [{ required: true, message: '请选择进场时间' }],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="info-col">
            <div class="info-col-label">出场：</div>
            <div class="info-col-content">
              <a-form-item>
                <a-time-picker
                  v-decorator="[
                    'out',
                    {
                      rules: [{ required: true, message: '请选择出场时间' }],
                    },
                  ]"
                  format="HH:mm:ss"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-col">
            <div class="info-col-label">上传凭证：</div>
            <div class="flex-1 info-col-content">
              <a-form-item>
                <PictureCardListUpload
                  v-decorator="[
                    'certificationList',
                    {
                      rules: [{ required: true, message: '请上传凭证' }],
                    },
                  ]"
                  ref="pictureCardListUpload"
                ></PictureCardListUpload>
              </a-form-item>
              <div class="mt-2 text-xs text-yellow-500">
                请上传监控或其他具有效力的证明图片（PNG、JPG、gif）作为凭证，总大小不超过10M
              </div>
            </div>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
import moment from 'moment'
import PictureCardListUpload from '@/components/PictureCardListUpload'
import fileDownload from 'js-file-download'
import axios from 'axios'
export default {
  components: { PictureCardListUpload },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '工友',
        width: '12%',
        align: 'center',
        dataIndex: 'workerName',
      },
      {
        title: '班组',
        width: '12%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '项目',
        width: '12%',
        align: 'center',
        dataIndex: 'projectName',
      },

      {
        title: '申请日期',
        width: '12%',
        align: 'center',
        dataIndex: 'createTime',
      },

      {
        title: '操作文员',
        width: '12%',
        align: 'center',
        dataIndex: 'operator',
      },
      {
        title: '操作时间',
        width: '12%',
        align: 'center',
        dataIndex: 'operatorTime',
      },
      {
        title: '已激活设备',
        width: '12%',
        align: 'center',
        dataIndex: 'activeDeviceNumber',
      },
      {
        title: '激活状态',
        width: '12%',
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row, index) => {
          const checked = Number(text) === 1
          return (
            <div>
              <a-switch
                loading={row.switchLoading}
                size="small"
                checked={checked}
                onChange={() => {
                  this.changeState(row, !checked, index)
                }}
              />
              {this.translateType(text, 'status')}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      selectedRowKeys: [],
      selectedRows: [],

      projectList: [],
      leaderList: [],
      workerList: [],
      months: [moment().format('YYYY-MM')],
    }
  },
  computed: {
    typesMap() {
      return {
        status: [
          { value: 0, label: '未激活' },
          { value: 1, label: '已激活' },
        ],
      }
    },
    attendenceWorkerIds() {
      return this.selectedRows.map(row => {
        return row.workerId
      })
    },
    monthOptions() {
      const result = []
      for (let i = 0; i < 6; i++) {
        const month = moment()
          .subtract(i, 'month')
          .format('YYYY-MM')
        result.push({
          label: month,
          value: month,
        })
      }
      return result
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/web/worker/checkIn/list', {
          ...this.getPageParams(_pageNo, _pageSize),
          dateList: this.months,
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async audit(row) {
      this.resetModal()
      const { data: detail } = await this.$axios.post(`/attendance/web/abnormal/detail/${row.id}`)
      this.modal.visible = true
      this.modal.title = '工人异常考勤审核'
      this.modal.data = { ...detail }
      this.safeSetFieldsValue(this.modal.form, { ...detail })
    },

    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const actionUrl = '/attendance/web/abnormalAudit'
            const { bizNo } = this.modal.data
            await this.$axios.post(actionUrl, {
              bizNo,
              ...params,
              inn: params.inn.format('HH:mm:ss'),
              out: params.out.format('HH:mm:ss'),
            })
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.resetTable()
      this.leaderList = await getLeaderList(projectCode)
    },
    onRowSelectChange(keys, rows) {
      this.selectedRowKeys = keys
      this.selectedRows = rows
    },
    async batchChangeState(rows, state) {
      try {
        await this.checkBatchActionRows(rows)
        await this.$axios.post('/attendance/web/worker/checkIn', {
          ids: rows.map(row => row.id),
          status: state,
        })
        this.doQuery()
        this.$message.success('操作成功!')
      } catch (e) {}
    },
    async changeState(row, state, index) {
      this.$set(this.dataSource[index], 'switchLoading', true)
      try {
        await this.batchChangeState([row], String(Number(state)))
      } catch (e) {}
      this.$set(this.dataSource[index], 'switchLoading', false)
    },
    async download() {
      const sourceSrc = 'https://van-tai-statics.oss-cn-hangzhou.aliyuncs.com/worker/worker_check_in.xlsx'
      const serverRes = await axios.get(sourceSrc, { responseType: 'blob' })
      fileDownload(serverRes.data, '模板.xlsx')
    },
    async importXlsx() {},
    beforeUpload(file) {
      const maxFileSize = 2
      const isLtSize = file.size / 1024 / 1024 < maxFileSize
      if (!isLtSize) {
        this.$message.error(`上传文件不能大于${maxFileSize}M!`)
        return false
      }
    },
    async handleUploadChange({ file, fileList }) {
      if (file.status === 'done') {
        this.$message.success(`${file.name} 上传成功`)
        this.doQuery(1)
      } else if (file.status === 'error') {
        console.log(file)
      }
    },
    doUpload(ev) {
      const { file, onError, onProgress, onSuccess } = ev
      console.log(file)
      this.$axios
        .post(
          '/attendance/web/worker/checkIn/import',
          { file: file },
          {
            postDataType: 'formData',
            onUploadProgress: function(progressEvent) {
              onProgress(
                {
                  percent: progressEvent.lengthComputable ? progressEvent.loaded / progressEvent.total : 1,
                },
                file
              )
            },
          }
        )
        .then(() => {
          // 第一个参数会回写到 file的response
          onSuccess({}, file)
        })
        .catch(e => {
          // console.log(e)
          // 第二个参数会回写到response,response 为字符串时，会直接展示为错误提示
          onError(e, `上传失败：${e.message}`)
        })
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
    const defaultProject = this.projectList.length ? this.projectList[0].value : ''
    if (defaultProject) {
      this.form.setFieldsValue({ projectCode: defaultProject })
      this.leaderList = await getLeaderList(defaultProject)
    }
  },
}
</script>

<style lang="less" scoped>
.info-row {
  display: flex;
  margin-bottom: 15px;
  .info-col {
    display: flex;
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
      .info-col-label {
        width: 100px;
        text-align: right;
      }
    }
    .info-col-content {
      margin-left: 5px;
    }
  }
}
.attendance-table {
  td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 2px 8px;
  }
}
</style>
